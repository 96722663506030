<script>
import {handlerTypes, payTypes} from '@/enums/paymentConfigs'
export default {
  name: 'EditForm',
  data() {
    return {
      payTypes,
      model: {
        active: false,
        android: false,
        handlerType: null,
        image: null,
        ios: false,
        merchantId: null,
        minimumPayable: null,
        name: null,
        password: null,
        payType: null,
        sortOrder: null,
        userName: null,
        web: false
      }
    }
  },
  computed: {
    handlerTypes() {
      return handlerTypes.filter(item => item.payType === this.model.payType)
    },
    discountLabel() {
      if (this.model.discountType === 'PERCENT') {
        return this.$t('shared.percent')
      } else {
        return this.$t('shared.IRR')
      }
    },
    discountValidations() {
      const validation = {
        numeric: true,
        required: true
      }
      if (this.model.discountType === 'PERCENT') {
        validation.min_value = 0
        validation.max_value = 100
      }
      return validation
    }
  }
}
</script>
